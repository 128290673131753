/*---------------------------------------
    ## Button
---------------------------------------*/
.btn {
  height: 55px;
  line-height: 55px;
  padding: 0 30px;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  border: 0;
  @include transition(all, 0.5s, ease);
  font-weight: 600;
  border-radius: 30px;
  z-index: 0;
  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
  }
  &:after {
    content: "";
    background: var(--heading-color);
    position: absolute;
    transition: $transition;
    z-index: -1;
    height: 100%;
    left: -35%;
    top: 0;
    transform: skew(30deg);
    transition-duration: 0.6s;
    transform-origin: top left;
    width: 0;
  }
  &:hover {
    &:after {
      height: 100%;
      width: 135%;
    }
  }
  i {
    font-size: 14px;
    float: right;
    padding-top: 16px;
    padding-left: 5px;
  }
  svg {
    font-size: 12px;
    margin-top: 20px;
    margin-left: 6px;
    float: right;
  }
  &.border-radius-0 {
    border-radius: 0;
  }
  &.border-radius-5 {
    border-radius: 5px;
  }
}
.btn-small {
  height: 50px;
  line-height: 50px;
  i {
    padding-top: 12px;
  }
}
.btn-base {
  color: #fff;
  background: var(--main-color);
  &:hover {
    color: #fff;
  }
}
.btn-red {
  color: #fff;
  background: #f14d5d;
  &:hover {
    color: #fff;
  }
}
.btn-white {
  color: var(--main-color) !important;
  background: #fff;
  &:hover,
  &:focus {
    color: #fff !important;
    background: var(--main-color);
  }
}
.btn-black {
  color: #fff !important;
  background: var(--heading-color);
  &:hover,
  &:focus {
    color: #fff !important;
    background: var(--main-color);
  }
}
.btn-black.sky {
  color: #fff !important;
  background: var(--heading-color);
  &:hover,
  &:focus {
    color: #fff !important;
    background: #50af9b;
  }
}
.btn-border-base {
  color: var(--heading-color);
  border: 2px solid var(--main-color);
  line-height: 52px;
  &:after {
    background: var(--main-color);
  }
  &:hover,
  &:focus {
    color: #fff;
  }
}
.btn-border-base-2 {
  color: #50af9b;
  border: 2px solid#50AF9B;
  line-height: 52px;
  &:after {
    background: #50af9b;
  }
  &:hover,
  &:focus {
    color: #fff;
  }
}
.btn-border-white {
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.7);
  line-height: 53px;
  &:hover,
  &:focus {
    color: #fff;
    background: var(--main-color);
    border: 2px solid var(--main-color);
  }
}
.btn-border-black {
  color: #a2a2a2;
  border: 2px solid #d4d4d4;
  line-height: 53px;
  &:hover,
  &:focus {
    color: #fff;
    background: var(--main-color);
    border: 2px solid var(--main-color);
  }
  &:after {
    background: var(--main-color);
  }
}
.btn-border-black-2 {
  color: #262626;
  border: 2px solid #262626;
  line-height: 53px;
  &:hover,
  &:focus {
    color: #fff;
    background: var(--main-color);
    border: 2px solid var(--main-color);
  }
  &:after {
    background: var(--main-color);
  }
}
.btn-border-gray {
  color: var(--heading-color);
  border: 2px solid #e3e3e3;
  line-height: 51px;
  background: #f8f9fc;
  &:hover,
  &:focus {
    color: #fff;
    background: var(--main-color);
    border: 2px solid var(--heading-color);
  }
}

.btn-base-color {
  color: #fff !important;
  background: #50af9b;
}

.read-more-text {
  position: relative;
  color: var(--heading-color);
  font-weight: 600;
  i {
    font-size: 18px;
    position: absolute;
    top: 1px;
    margin-left: 3px;
    transition: 0.4s;
  }
  svg {
    font-size: 12px;
    position: absolute;
    top: 5px;
    margin-left: 6px;
    transition: 0.4s;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: var(--main-color);
    height: 1px;
    width: 0;
    transition: 0.4s;
    visibility: hidden;
    opacity: 0;
  }
  &:hover {
    i {
      padding-left: 6px;
    }
    &:after {
      width: 100%;
      visibility: visible;
      opacity: 1;
    }
  }
}

.read-more-arrow {
  height: 35px;
  width: 35px;
  line-height: 35px;
  color: #fff;
  text-align: center;
  background: var(--main-color);
  border-radius: 50%;
  display: inline-block;
  &:hover {
    background: #0a1851;
    color: #fff;
  }
}

/*------------------------------------------------
    ## Back Top
------------------------------------------------*/
.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 44px;
  height: 44px;
  color: $color-white;
  background-color: var(--main-color);
  text-align: center;
  line-height: 44px;
  z-index: 99;
  font-size: 25px;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: none;
  animation: backto-top-bounce 4s infinite ease-in-out;
}
@-webkit-keyframes backto-top-bounce {
  0% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(8px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
}

@keyframes backto-top-bounce {
  0% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(8px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
}

/*-----------------------------------------
    ## Preloader Css
-------------------------------------------*/
.pre-wrap {
  position: fixed;
  content: "";
  transform: translate(-100%, -240%);
  font-size: 62px;
}
.preloader-inner {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999999;
  background-color: #030724;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .cancel-preloader {
    position: absolute;
    bottom: 30px;
    right: 30px;
    a {
      background-color: $color-white;
      font-weight: 600;
      text-transform: capitalize;
      color: var(--main-color);
      width: 200px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      border-radius: 30px;
      display: block;
      @include transition(all, 0.4s, ease);
      &:hover {
        background-color: var(--heading-color);
        color: $color-white;
      }
    }
  }
}
.spinner {
  margin: 120px auto;
  width: 60px;
  height: 60px;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: var(--main-color);
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/*-----------------------------------------
    ## video
------------------------------------------*/
.video-thumb {
  position: relative;
  .video-play-btn-hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}
.video-play-btn {
  border-radius: 50%;
  background: #fff;
  width: 50px;
  height: 50px;
  display: inline-block;
  line-height: 50px;
  position: relative;
  z-index: 0;
  text-align: center;
  animation: ripple-white3 2.5s linear infinite;
  z-index: 3;
  box-shadow: 0 4px 15px rgba(19, 16, 34, 0.1);
  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background: rgba(247, 247, 247, 0.4);
    top: -15px;
    left: -15px;
  }
  i {
    color: var(--main-color);
    margin-left: 6px;
    font-size: 28px;
  }
  &.video-play-btn-base {
    background: var(--main-color);
    i {
      color: #fff;
    }
    &:after {
      display: none;
    }
  }
  &.small {
    width: 55px;
    height: 55px;
    line-height: 60px;
    i {
      font-size: 18px;
    }
  }
}

/*-----------------------------------------
    ## Breadcumb
------------------------------------------*/
.breadcrumb-area {
  padding: 222px 0 108px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-top: -120px;
  .banner-bg-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    background-size: 102% 96%;
  }
  .breadcrumb-inner {
    position: relative;
    z-index: 2;
  }
  .page-title {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 5px;
    color: white;

  }
  .page-list {
    margin: 0;
    padding: 0;
    li {
      font-weight: 600;
      list-style: none;
      display: inline-block;
      position: relative;
      color: white;

      &:first-child {
        padding-left: 0;
        margin-left: 0;
        &:after {
          display: none;
        }
      }
    }
  }
}

/********* social-media *********/
.social-media {
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 2;
  li {
    display: inline-block;
    margin: 0 2px;
    a {
      height: 30px;
      width: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 5px;
      display: inline-block;
      background: #333f4d;
      color: #fff;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: var(--main-color);
        color: #fff;
      }
      &.facebook {
        color: $facebook;
        border-color: $facebook;
      }
      &.twitter {
        color: $twitter;
        border-color: $twitter;
      }
      &.instagram {
        color: $instagram;
        border-color: $instagram;
      }
      &.youtube {
        color: $youtube;
        border-color: $youtube;
      }
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.social-media-light {
  a {
    background: rgba(253, 74, 54, 0.15);
    display: inline-block;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    text-align: center;
    line-height: 31px;
    i {
      color: var(--main-color);
      font-size: 15px;
      transition: 0.4s;
    }
    &:hover {
      background: var(--main-color);
      i {
        color: #fff;
      }
    }
  }
}

/********* slider-control *********/
.slider-control-round {
  .slick-slider {
    .slick-next {
      height: 46px;
      width: 46px;
      line-height: 44px;
      margin: 0 10px;
      border-radius: 50%;
      border: 0;
      transition: 0.4s;
      box-shadow: none;
      color: var(--heading-color);
      border: 1px solid var(--main-color);
      padding: 9px;
      cursor: pointer;
      font-size: 18px;
      background: transparent;
      position: absolute;
      right: 0;
      top: -95px;
      &:hover {
        background: var(--main-color);
        color: #fff;
      }
    }
    .slick-prev {
      left: 0;
      height: 46px;
      width: 46px;
      line-height: 44px;
      margin: 0 10px;
      border-radius: 50%;
      border: 0;
      transition: 0.4s;
      box-shadow: none;
      color: var(--heading-color);
      border: 1px solid var(--main-color);
      padding: 9px;
      cursor: pointer;
      font-size: 18px;
      background: transparent;
      position: absolute;
      left: unset;
      top: -95px;
      right: 60px;
      &:hover {
        background: var(--main-color);
        color: #fff;
      }
    }
  }
}
.slider-control-square {
  .slick-slider {
    .slick-next {
      height: 46px;
      width: 46px;
      line-height: 44px;
      margin: 0 10px;
      border-radius: 8px;
      border: 0;
      transition: 0.4s;
      box-shadow: none;
      color: #fff;
      border: 1px solid #50af9b;
      padding: 9px;
      cursor: pointer;
      font-size: 18px;
      background: transparent;
      position: absolute;
      right: 0;
      top: -95px;
      &:hover {
        background: #50af9b;
        color: #fff;
      }
    }
    .slick-prev {
      left: 0;
      height: 46px;
      width: 46px;
      line-height: 44px;
      margin: 0 10px;
      border-radius: 8px;
      border: 0;
      transition: 0.4s;
      box-shadow: none;
      color: #fff;
      border: 1px solid #50af9b;
      padding: 9px;
      cursor: pointer;
      font-size: 18px;
      background: transparent;
      position: absolute;
      left: unset;
      top: -95px;
      right: 60px;
      &:hover {
        background: #50af9b;
        color: #fff;
      }
    }
  }
}

.slider-control-square {
  .owl-nav {
    button {
      height: 45px;
      width: 60px;
      line-height: 46px;
      margin: 0 8px;
      border-radius: 0;
      border: 1px solid var(--main-color);
      transition: 0.4s;
      box-shadow: none;
      color: var(--main-color);
      background: transparent;
      font-size: 20px;
      padding: 0;
      cursor: pointer;
      &:hover {
        background: var(--main-color);
        color: #fff;
      }
    }
    .owl-prev {
      margin-left: 0;
    }
    .owl-next {
      margin-left: 0;
    }
  }
}

.slider-control-right-top {
  .owl-nav {
    position: absolute;
    right: 0;
    top: -95px;
  }
}

.slider-control-dots {
  .owl-dots {
    text-align: center;
    padding-top: 23px;
    .owl-dot {
      margin: 0 2px;
      border: 0;
      background: none;
      span {
        display: block;
        border-radius: 50%;
        background-color: var(--heading-color);
        width: 8px;
        height: 8px;
        position: relative;
        @include transition(all, 0.3s, ease);
        &:after {
          position: absolute;
          content: "";
          top: -5px;
          left: -5px;
          border: 1px solid var(--main-color);
          border-radius: 50%;
          width: calc(100% + 10px);
          height: calc(100% + 10px);
          transform: scale(0);
          @include transition(all, 0.3s, ease);
        }
      }
      &.active span {
        background: var(--main-color);
        &:after {
          transform: scale(1);
        }
      }
      &:focus {
        outline: 0;
      }
    }
  }
}

.single-list-inner {
  margin: 0;
  padding: 0;
  li {
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.style-check {
    li {
      list-style: none;
      svg,
      i {
        margin-right: 10px;
        color: var(--main-color);
        font-size: 18px;
      }
      .sky {
        color: #50af9b;
      }
    }
  }
  &.style-heading {
    li {
      color: var(--heading-color);
      font-weight: 600;
    }
  }
}

/*--------------------------------------------------------------
# Globals
--------------------------------------------------------------*/
.border-radius-6 {
  border-radius: 6px;
  img {
    border-radius: 6px;
  }
}
.ratting-inner {
  color: #ffc107;
}
.bg-base {
  background: var(--main-color);
}
.bg-base-2 {
  background: #50af9b !important;
}
.bg-green {
  background: #1dc295;
}
.bg-blue {
  background: #2878eb;
}
.bg-red {
  background: #f14d5d;
}
.bg-gray {
  background: #f8f9fc;
}
.bg-sky {
  background: #f9f9f9;
}
.bg-gradient-gray {
  background-image: linear-gradient(#f8f9fc, #d9e5ff);
}
.bg-black {
  background: var(--heading-color);
}
.bg-light {
  background: #f7fafc;
}
.bg-light-pink {
  background: #fff9f5;
}
.bg-relative {
  position: relative;
}
.bg-cover {
  background-size: cover !important;
  background-position: center;
}
.bg-position-right {
  background-position: right;
  background-repeat: no-repeat;
}
.bg-left-50 {
  background-position: left;
  background-repeat: no-repeat;
  background-size: 50% 100%;
}
.bg-overlay {
  position: relative;
  background-size: cover !important;
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(10, 24, 81, 0.88);
  }
  .container {
    position: relative;
    z-index: 2;
  }
  .bg-z-index {
    position: relative;
    z-index: 2;
  }
}
.bg-overlay-light {
  position: relative;
  background-size: cover !important;
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(10, 24, 81, 0.5);
  }
  .container {
    position: relative;
    z-index: 2;
  }
  .bg-z-index {
    position: relative;
    z-index: 2;
  }
}
.bg-parallex {
  background-attachment: fixed;
  background-position: center 0;
}
.image-hover-animate {
  overflow: hidden;
  img {
    transform: scale(1.02);
    transition: 0.7s;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}
.box-shadow {
  box-shadow: 0px 8px 30px rgb(0 0 0 / 10%);
}

.pd-top-60 {
  padding-top: 60px;
}
.pd-top-90 {
  padding-top: 90px;
}
.pd-top-100 {
  padding-top: 100px;
}
.pd-top-110 {
  padding-top: 110px;
}
.pd-top-115 {
  padding-top: 115px;
}
.pd-top-120 {
  padding-top: 120px;
}
.pd-top-200 {
  padding-top: 200px;
}

.mg-top-120 {
  margin-top: 120px;
}
.mg-bottom-120 {
  margin-bottom: 120px;
}

.pd-bottom-60 {
  padding-bottom: 60px;
}
.pd-bottom-70 {
  padding-bottom: 70px;
}
.pd-bottom-80 {
  padding-bottom: 80px;
}
.pd-bottom-90 {
  padding-bottom: 90px;
}
.pd-bottom-100 {
  padding-bottom: 100px;
}
.pd-bottom-110 {
  padding-bottom: 110px;
}
.pd-bottom-115 {
  padding-bottom: 115px;
}
.pd-bottom-120 {
  padding-bottom: 120px;
}

.mg-top--82 {
  margin-top: -100px;
}
.position-bottom-left {
  position: absolute;
  left: 30px;
  bottom: 150px;
  width: 50px;
}
.border-right-1 {
  border-right: 1px solid #ededed;
}
.border-radius-20 {
  border-radius: 20px;
}
.border-radius-5 {
  border-radius: 5px;
}
.color-base {
  color: var(--main-color);
}

.border-1 {
  border: 1px solid #ededed;
}
.border-top-1 {
  border-top: 1px solid #ededed;
}
.border-right-1 {
  border-right: 1px solid #ededed;
}
.border-radius-20 {
  border-radius: 20px;
}
.border-radius-5 {
  border-radius: 5px !important;
}

.image-hover-rotate {
  .rotate-img {
    transition: 0.4s;
  }
  &:hover {
    .rotate-img {
      transform: rotateY(180deg);
    }
  }
}
